import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Box, Divider, Heading, HStack, LinkBox, LinkOverlay, Skeleton, VStack } from '@chakra-ui/react';
import getPseudoRandomAccentColorSchemeFromString from 'src/lib/get-random-accent-color-scheme-from-string';
import WeekSelector from 'src/components/common/week-selector-home';

type LoadingProps = {
	isLoading: true;
};

type ResolvedProps = {
	isLoading?: false;
	heading: string;
	numOfWeeks: number;
	imageUrl?: string | null;
	href: string;
};

export type ChallengePromoCardProps = LoadingProps | ResolvedProps;

export const getGradientFromScheme = (colorScheme: string, direction = 'to-br') => {
	switch (colorScheme) {
		case 'blue':
			return `linear(${direction}, teal.300, blue.400)`;
		case 'purple':
			return `linear(${direction}, purple.200, blue.500)`;
		case 'red':
			return `linear(${direction}, red.300, red.600)`;
		default:
			return '';
	}
};

export const getAccentColorFromScheme = (colorScheme: string) => {
	switch (colorScheme) {
		case 'blue':
			return 'blue.400';
		case 'purple':
			return 'blue.500';
		case 'red':
			return 'red.600';
		default:
			return '';
	}
};

const ChallengePromoCard = (props: ChallengePromoCardProps) => {
	const colorScheme = getPseudoRandomAccentColorSchemeFromString(
		props.isLoading ? '' : props.heading,
		['blue', 'purple', 'red']);

	return (
		<LinkBox
			shadow="card"
			roundedBottom="20px"
			mt={{ base: 0, lg: 5 }}
			bgColor={getAccentColorFromScheme(colorScheme)}
			role="group"
			color="white"
			w={{ base: 'full', lg: 'min-content' }}
			h="min-content"
			minHeight={{ base: 200, lg: 300 }}
		>
			<HStack
				bgGradient={getGradientFromScheme(colorScheme)}
				spacing={{ base: 0, lg: 5 }}
				px={5}
				pb={{ base: 3, lg: 6 }}
			>
				<Skeleton
					w="2xs"
					h={44}
					mt={{ base: 0, lg: -5 }}
					alignSelf="flex-start"
					overflow="hidden"
					rounded="sm"
					display={{ base: 'none', lg: 'block' }}
					isLoaded={!props.isLoading}
				>
					<Box
						w="full"
						h="full"
						pos="relative"
						transitionProperty="common"
						transitionDuration="normal"
						_groupHover={{ transform: 'scale(1.1)' }}
					>

						{
							!props.isLoading && props.imageUrl && (
								<Image src={props.imageUrl} layout="fill" objectFit="cover" sizes="512px" />
							)
						}
					</Box>
				</Skeleton>

				<VStack
					align="flex-start"
					alignSelf="stretch"
					spacing={{ base: 2, lg: 5 }}
					pt={5}
					w="2xs"
				>
					<Skeleton isLoaded={!props.isLoading}>
						<Heading size="3">
							{props.isLoading ? 0 : props.numOfWeeks} Week Challenge
						</Heading>
					</Skeleton>

					<Skeleton isLoaded={!props.isLoading} flex={1}>
						<Link passHref href={props.isLoading ? '#' : props.href}>
							<LinkOverlay>
								<Heading size="2a">
									{props.isLoading ? 'Quick Workouts' : props.heading}
								</Heading>
							</LinkOverlay>
						</Link>
					</Skeleton>
				</VStack>
			</HStack>

			<Divider opacity={1} />

			<VStack
				align="flex-start"
				spacing={2}
				p={5}
				pt={{ base: 3, lg: 5 }}
				pos="relative"
			>
				<b>Go to week</b>

				<WeekSelector
					isLoading={props.isLoading}
					numOfWeeks={props.isLoading ? 6 : props.numOfWeeks}
					baseHref={props.isLoading ? '' : props.href}
					stackProps={{
						// W: 100% / full wasn't working here for some reason
						w: { base: 'calc(100vw - (var(--chakra-sizes-5) * 2))', md: 'auto' },
						whiteSpace: 'nowrap',
						shouldWrapChildren: true,
						display: 'block'
					}} />
			</VStack>
		</LinkBox>
	);
};

export default ChallengePromoCard;
