import React from 'react';
import Link from 'next/link';
import { Stack, Button, Skeleton, StackProps, ButtonProps } from '@chakra-ui/react';

type LoadingProps = {
	isLoading: true;
};

type ResolvedProps = {
	isLoading?: false;
	numOfWeeks: number;
	baseHref?: string;
	onClick?: (i: number) => void;
};

type CommonProps = {
	stackProps?: StackProps;
	activeWeek?: number;
};

export type WeekSelectorProps = (LoadingProps | ResolvedProps) & CommonProps;

const WrappedButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
	<Button
		ref={ref}
		// rounded="full"
		boxSize={10}
		borderWidth="2px"
		borderColor="white"
		color="white"
		bgColor="rgba(255, 255, 255, 0.1)"
		overflow="hidden"
		position="relative"
		_hover={{
			bgColor: 'rgba(255, 255, 255, 0.3)'
		}}
		_active={{
			bgColor: 'white',
			color: 'black'
		}}
		{...props} />
));

const WeekSelector = (props: WeekSelectorProps) => {
	return (
		<Skeleton isLoaded={!props.isLoading} className='overflow-scroll-horzontial-container'>
			<Stack
				position="relative"
				zIndex="0"
				spacing={3}
				direction="row"
				// overflow="auto"
				{...props.stackProps}
				// overflowX='auto'
				// overflowY='auto'
				className='overflow-scroll-horzontial'
			>
				{
					Array.from({ length: props.isLoading ? 6 : props.numOfWeeks }).fill(null).map((_, i) => props.isLoading ? (
						<WrappedButton key={i}>{i + 1}</WrappedButton>
					) : (props.baseHref ? (
						<Link key={i} passHref href={`${props.baseHref}?week=${i + 1}`}>
							<WrappedButton as="a" isActive={props.activeWeek === i}>{i + 1}</WrappedButton>
						</Link>
					) : (
						<WrappedButton
							key={i}
							isActive={props.activeWeek === i}
							onClick={() => {
								if (props.onClick) {
									props.onClick(i);
								}
							}}
						>
							{i + 1}
						</WrappedButton>
					))
					)
				}
			</Stack>
		</Skeleton>
	);
};

export default WeekSelector;
